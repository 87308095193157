<template>
<v-container>
  <h1 class="title grey--text">排课计划</h1>
  <!-- 该项目全部班级活动总览 -->
  <v-card style="height: 100%;" class="pa-4 my-4">
    <v-subheader>
      选择排课项目
    </v-subheader>
    <v-row>
      <v-col>
        <v-select
          v-model="programId"
          :items="programs"
          item-text="title"
          item-value="id"
          label="排课项目"
        ></v-select>
      </v-col>
    </v-row>
  </v-card>
  <!-- 该项目全部班级活动总览 -->
  <v-card v-if="programId" style="height: 100%;" class="pa-4 my-4">
    <v-subheader>
      日历总览
    </v-subheader>
    <v-row>
      <v-col>
      <y-lesson-calendar :key="calendarKey" :programIds="[this.programId]"/>
      </v-col>
    </v-row>
  </v-card>
  <!-- 开始排课 -->
  <v-row>
    <v-col>
      <v-btn color='primary'
        @click="showScheduler = !showScheduler"
        :disabled="!programId"
      >{{showScheduler ? '收起排课' : '开始排课'}}</v-btn>
    </v-col>
  </v-row>
  <!-- 第1步，选择基准日期 -->
  <v-card style="height: 100%;" class="pa-4 my-4" v-if="showScheduler">
    <v-card style="height: 100%;" class="pa-4 my-4">
      <v-subheader :inset="false">
        排课日期(多选)
      </v-subheader>
      <v-row justify="center">
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="6"
          justify="center"
        >
          <v-date-picker
            v-model="templateReferenceDates"
            multiple
            locale="zh-cn"
            :first-day-of-week="1"
          ></v-date-picker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="6"
          fill-height
        >
          <v-card style="height: 100%;" class="pa-5" outlined>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="templateReferenceDates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="templateReferenceDates"
                  multiple
                  chips
                  small-chips
                  label="已选新增排课日期"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-combobox>
              </template>
              <v-date-picker
                v-model="templateReferenceDates"
                multiple
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  取消
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(templateReferenceDates)"
                >
                  关闭
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- 第2步，选择开课的教师 -->
    <v-card style="height: 100%;" class="pa-4 my-4">
      <v-select
        v-model="lessonTemplateTutorId"
        :items="tutors"
        item-text="tutor"
        item-value="tutorId"
        label="排课教师"
      ></v-select>
    </v-card>

    <!-- 第3步，针对班级进行偏移设定 -->
    <v-card v-if="lessonTemplateTutorId" class="pa-4 my-4">
      <v-row v-for="(singleClass) in classes" :key="singleClass.class_id">
        <v-col v-if="singleClass.tutors.map(tutor => tutor.tutorId).includes(lessonTemplateTutorId)">
          <v-card outlined class="pa-4">
            <v-switch
              v-model="singleClass.schedule"
              :label="`排课班级: ${singleClass.class} `"
            ></v-switch>
            <div v-if="singleClass.schedule">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="singleClass.tutorId"
                    :items="singleClass.tutors"
                    item-text="tutor"
                    item-value="tutorId"
                    label="本班任课教师"
                    disabled
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="singleClass.lessonDayShiftFromReference"
                    :items="lessonDayShiftFromReferenceItems"
                    item-text="description"
                    item-value="dayShiftFromReference"
                    label="本班活动日期偏移"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="singleClass.startTime"
                    v-bind:label="timeLabelDisplay(singleClass.startTime)"
                    prepend-icon="access_time"
                    readonly
                    @click="singleClass.menuTimePickerShow = ! singleClass.menuTimePickerShow"
                  ></v-text-field>
                  <v-time-picker
                    v-if="singleClass.menuTimePickerShow"
                    v-model="singleClass.startTime"
                    format="24hr"
                    locale="zh-cn"
                    @change="singleClass.menuTimePickerShow = ! singleClass.menuTimePickerShow"
                    transition="scale-transition"
                  ></v-time-picker>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- 第4步，发送后端进行模版与课程生成 -->
    <v-row>
      <v-col>
        <v-btn color='primary'
          @click="upsertLessonSessionBatch"
          :disabled="templateReferenceDates.length === 0"
        >创建</v-btn>
        <v-btn text @click="cancel">取消</v-btn>
      </v-col>
    </v-row>

  </v-card>

  <v-main class="my-5">
    <v-layout justify-center class="mb-2">
      <v-flex xs12>
        <v-form name="lesson-form"
          ref="createLessonForm"
          v-model="isFormVisiblyValid"
          lazy-validation
        >
        </v-form>
      </v-flex>
    </v-layout>
  </v-main>

  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</v-container>
</template>

<script>
import Vue from 'vue'
import LessonService from '@/services/LessonService'
import ProgramService from '@/services/ProgramService'
import { format, add } from 'date-fns'
import { ClassStatusLookup, LessonStatusLookup } from '../enums'
import YLessonCalendar from '@/components/YLessonCalendar'

export default {
  components: {
    YLessonCalendar
  },
  data () {
    return {
      programs: [], // 可供选择的项目列表
      programId: null, // 需要排课的项目ID
      program: {}, // 项目信息
      showScheduler: false, // 来否展示排课的设定单元
      classes: [], // 该项目及教师所关联的班级信息
      templateReferenceDates: [], // 选中的基准排课日期
      menu: false, // 排课日期先择菜单
      tutors: [], // 可供选择的私教(由本项目所有班级负责教师组成)
      lessonTemplateTutorId: null, // 本次排课选定的私教ID
      calendarKey: 0, // 排课表Key，改变该数值会触发组件更新
      lessonDayShiftFromReferenceItems: [ // 班级实际排课与排课日期的偏移选项
        {
          description: '提前3天',
          dayShiftFromReference: -3
        },
        {
          description: '提前2天',
          dayShiftFromReference: -2
        },
        {
          description: '提前1天',
          dayShiftFromReference: -1
        },
        {
          description: '当天(与基准日期相同)',
          dayShiftFromReference: 0
        },
        {
          description: '推后1天',
          dayShiftFromReference: 1
        },
        {
          description: '推后2天',
          dayShiftFromReference: 2
        },
        {
          description: '推后3天',
          dayShiftFromReference: 3
        },
        {
          description: '推后4天',
          dayShiftFromReference: 4
        },
        {
          description: '推后5天',
          dayShiftFromReference: 5
        },
        {
          description: '推后6天',
          dayShiftFromReference: 6
        }
      ],
      // form validation rules
      required: (value) => !!value || '必填',
      titleRules: [
        v => !!v || '必填项'
      ],
      descriptionRules: [
        v => !!v || '需要填写活动描述'
      ],
      isFormVisiblyValid: true
    }
  },
  watch: {
    lessonTemplateTutorId (newLessonTemplateTutorId) { // 每当更换排课教师时，班级教师自动选取该私教
      this.classes.forEach((singleClass) => {
        if (singleClass.tutors.map(tutor => tutor.tutorId).includes(newLessonTemplateTutorId)) { // 与该教师相关班级会选择该教师
          singleClass.tutorId = newLessonTemplateTutorId
        }
      })
    },
    async $route (to, from) {
      if (this.$route.query.organizationId || this.$route.query.programId) { // 所接受的路径参数
        console.log('检测到路径参数变化')
        this.$store.dispatch('setQueryingState', true)
        console.log(this.$route.query)

        if (this.$route.query.programId) {
          this.programId = this.$route.query.programId
          const resProgram = await ProgramService.getPrograms({ programIds: [this.programId], statusCodes: [9], attatchPaidedStudentsInfo: false, attatchTags: false, attatchPricePackages: false, attatchMedia: false, attatchDetailedMedias: false })
          this.programs = [resProgram.data[0]]
        }

        if (this.$route.query.organizationId) {
          const organizationId = parseInt(this.$route.query.organizationId)
          // get programs
          const resPrograms = await ProgramService.getPrograms({ organizationIds: [organizationId], statusCodes: [9], attatchPaidedStudentsInfo: false, attatchTags: false, attatchPricePackages: false, attatchMedia: false, attatchDetailedMedias: false })
          this.programs = resPrograms.data
        }

        this.$store.dispatch('setQueryingState', false)
      }
    },
    async programId (newProgramId) { // 用户改变下拉项目菜单选项
      // 根据所选教师 请求 classInfo
      const resClasses = await ProgramService.getClasses({
        programIds: [newProgramId],
        attachTutors: true,
        statusCodes: [
          ClassStatusLookup.ACTIVE
        ]
      })
      this.classes = resClasses.data
      console.log(`classes under programId: ${newProgramId}`)
      console.log(this.classes)

      // 准备需要本项目相关班级所有教师列表
      this.classes.forEach((singleClass) => {
        if (singleClass.tutors) {
          singleClass.tutors.forEach((tutor) => {
            const tutorIds = this.tutors.map(tutor => tutor.tutorId)
            if (tutorIds.includes(tutor.tutorId)) {
            } else {
              this.tutors.push(tutor)
            }
          })
        }
      })
      if (this.tutors.length === 1) { // 如果仅有一名教师直接选定该教师为本次排课教师
        this.lessonTemplateTutorId = this.tutors[0].tutorId
      }

      // 数据复位
      this.templateReferenceDates = [] // 清空日期选定(否则会误设很多课程)
      this.showScheduler = false
      this.structClassesData()

      this.forceRerenderCalendar()
    }
  },
  computed: {
    templatesInfo () {
      return this.templateReferenceDates.map(date => {
        let lessons = []
        this.classes.forEach(singleClass => {
          if (singleClass.schedule) {
            lessons.push({
              id: null,
              startTime: format(add(new Date(`${date} ${singleClass.startTime}`), { days: singleClass.lessonDayShiftFromReference }), 'yyyy-MM-dd HH:mm:ss'), // 考虑了日期偏移设置
              venueId: singleClass.venue_id,
              venueStatusCode: null,
              tutorId: singleClass.tutorId,
              tutorStatusCode: null,
              classId: singleClass.class_id,
              statusCode: LessonStatusLookup.SCHEDULED
            })
          }
        })
        console.log('this.lessonTemplateTutorId:')
        console.log(this.lessonTemplateTutorId)
        return {
          // 以下模版信息
          templateId: null,
          templateTitle: null,
          templateDescription: null,
          templateProgramId: this.programId,
          templateStartDate: date,
          templateTutorId: this.lessonTemplateTutorId,
          // 以下课程信息
          lessons
        }
      })
    }
  },
  async created () {
  },
  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    // 提取路径参数: 确定项目列表
    if (this.$route.query.programId) {
      this.programId = this.$route.query.programId
      const resProgram = await ProgramService.getPrograms({ programIds: [this.programId], statusCodes: [9], attatchPaidedStudentsInfo: false, attatchTags: false, attatchPricePackages: false, attatchMedia: false, attatchDetailedMedias: false })
      this.programs = [resProgram.data[0]]
    }
    if (this.$route.query.organizationId) {
      const organizationId = parseInt(this.$route.query.organizationId)
      // get programs
      const resPrograms = await ProgramService.getPrograms({ organizationIds: [organizationId], statusCodes: [9], attatchPaidedStudentsInfo: false, attatchTags: false, attatchPricePackages: false, attatchMedia: false, attatchDetailedMedias: false })
      this.programs = resPrograms.data
    }
    if (this.$route.query.tutorId) { // not implemented
      this.lessonTemplateTutorId = parseInt(this.$route.query.tutorId)
      // get classes
      // get programIds
      // get programs
    }

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },

  beforeDestroy () {
  },

  methods: {
    timeLabelDisplay (time) {
      if (time) {
        return '活动时间: ' + time
      } else {
        return '活动时间'
      }
    },
    async upsertLessonSessionBatch () {
      this.$store.dispatch('setQueryingState', true)
      await LessonService.upsertLessonSessionBatch({ templatesInfo: this.templatesInfo })

      // 数据复位
      this.templateReferenceDates = [] // 清空日期选定(否则会误设很多课程)
      this.showScheduler = false
      this.structClassesData()

      this.forceRerenderCalendar()
      this.$store.dispatch('setQueryingState', false)
    },
    structClassesData () {
      const self = this
      this.classes.forEach((singleClass, index) => {
        Vue.set(self.classes[index], 'schedule', false)
        Vue.set(self.classes[index], 'lessonDayShiftFromReference', 0)
        Vue.set(self.classes[index], 'tutorId', null)
        Vue.set(self.classes[index], 'startTime', '')
        Vue.set(self.classes[index], 'menuTimePickerShow', false)
        console.log(singleClass)
        if (singleClass.tutors.length > 0) { // 如果该班级通过class_tutor_assignment(未来均使用此来设定教师)设定了教师
          if (singleClass.tutors.length === 1) { // 如果只有1名教师，直接选定
            singleClass.tutorId = singleClass.tutors[0].tutorId
          }
        } else { // 如果该班级未通过class_tutor_assignment(未来均使用此来设定教师)设定教师，只具有一名负责教师
          singleClass.tutors.push({
            tutor: singleClass.tutor,
            tutorId: singleClass.tutor_id
          })
          singleClass.tutorId = singleClass.tutor_id
        }
      })
    },
    cancel () {
      // route
      this.$router.go(-1)
    },
    getEventColor (event) {
      return event.color
    },
    forceRerenderCalendar () {
      this.calendarKey += 1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
